import React, { useState } from "react"
import Layout from "../../components/layout";
import { Container } from "@material-ui/core";
import { PageHeader } from "../../components/PageHeader";
import defaultImage from "../../images/what/default.png";
import categoryGayart from "../../images/what/gayart/kings-without-queens.png";
import categoryHagen from "../../images/what/tribute/nina-hagen.png";
import categoryProud from "../../images/what/proud/through-the-rainbow.png";
import categoryGayart18 from "../../images/what/gayart18/butterflies.png";
import categoryOcean from "../../images/what/ocean/ocean-6.png";
import categorySelfies from "../../images/what/selfies/selbstbildnis-sickhead.png";
import categoryFun from "../../images/what/fun/hohoho.png";
import categoryYourFace from "../../images/what/yourface/ink-schmetterlinge.png";
import styled from "@emotion/styled";

/*
 * ====================================================================
 *                            Constants
 * ====================================================================
 */

interface WhatSection {
  title: string;
  path: string;
  image: string;
}

const sections: WhatSection[] = [
  {
    title: "Your Face",
    image: categoryYourFace,
    path: "/what/yourface"
  },
  {
    title: "Be My Ocean",
    image: categoryOcean,
    path: "/what/ocean"
  },
  {
    title: "Proud",
    image: categoryProud,
    path: "/what/proud"
  },
  {
    title: "Gayart",
    image: categoryGayart,
    path: "/what/gayart",
  },
  {
    title: "Gayart 18+",
    image: categoryGayart18,
    path: "/what/gayart18"
  },
  {
    title: "Tribute",
    path: "/what/tribute",
    image: categoryHagen
  },
  {
    title: "Selfies",
    image: categorySelfies,
    path: "/what/selfies"
  },
  {
    title: "Fun",
    image: categoryFun,
    path: "/what/fun"
  },
];

/*
 * ====================================================================
 *                            Components
 * ====================================================================
 */

const PageWrapper = styled.div`
  display: flex;
  height: calc(100vh - 200px);
  flex-direction: row;

  & > * {  
    width: 50%;
  }

  @media (max-width: 700px) {
    & > * {  
      width: 100%;
    }
    & > div:nth-of-type(2) {
      display: none;
    }
  }
`;

const SectionList = styled.ul`

  font-size: 2.1rem;
  list-style: none;
  padding: 0;
  text-align: center;

  li {
    padding: 16px 0;
  
    a {
      color: white;
    }
    
    span {
      opacity: 0.6;
      cursor: not-allowed;
    }

  }

`;

const SectionListItem: React.FC<{
  section: WhatSection;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}> = props => {
  return (
    <li>

      {!!props.section.path && (
        <a
          href={props.section.path}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {props.section.title}
        </a>
      )}

      {!props.section.path && (
        <span
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {props.section.title}
        </span>
      )}

    </li>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  img {
    position: relative;
    top: 0;
    object-fit: contain;
    width: 100%;
    height: auto;
  }

`;


/*
 * ====================================================================
 *                              Page
 * ====================================================================
 */

const Page = ( { data } ) => {

  const [hoverIndex, setHoverIndex] = useState<number | null>( null );

  const hoveredSection = hoverIndex !== null
    ? sections[hoverIndex]
    : null;

  const image = !!hoveredSection
    ? hoveredSection.image
    : defaultImage;

  return (
    <Layout>
      <Container maxWidth="md">

        <PageHeader>
          What
        </PageHeader>

        <PageWrapper>

          <div>
            <SectionList>
              {sections.map( ( item, index ) => {
                return (
                  <SectionListItem
                    key={index}
                    section={item}
                    onMouseEnter={() => setHoverIndex( index )}
                    onMouseLeave={() => setHoverIndex( null )}
                  >
                    {item.title}
                  </SectionListItem>
                );
              } )}
            </SectionList>
          </div>

          <div>
            <ImageWrapper>
              <img src={image} alt={"preview"}/>
            </ImageWrapper>
          </div>

        </PageWrapper>

      </Container>
    </Layout>
  );

};

export default Page;
